import React, { useContext, useEffect, useState } from "react";

import MomentUtils from "@date-io/moment";
import { Button, FormControl, MenuItem, Select } from "@material-ui/core";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { navigate } from "gatsby";
import { isEmpty } from "lodash";
import moment from "moment";
import Swal from "sweetalert2";

import TotalPackDashboardGrid from "~/components/Appointments/TotalPackDashboardGrid";
import PrivateRoute from "~/components/Authentication/PrivateRoute";
import LoadingError from "~/components/Loaders/LoadingError";
import { FiltersContext } from "~/components/Stores/FilterStore";
import api from "~/utils/api/api";
import appointmentService from "~/utils/api/v1/appointmentService";
import { isProd } from "~/utils/environment";
import { TotalPackAppointment } from "~/utils/interfaces/Appointment";

moment.locale("es");

interface AppointmentRequest {
  data: {
    count: number;
    next: string | null;
    previous: string | null;
    results: TotalPackAppointment[];
  };
}

const IndexPage = (props): JSX.Element => {
  // @ts-expect-error
  const [commonFilters, filtersDispatch] = useContext(FiltersContext);
  const [filters, setFilters] = useState({
    status: "without-connection",
    insurance: "all",
  });

  const [error, setError] = useState<Object>({});
  const [loading, setLoading] = useState<boolean>(false);
  const [appointments, setAppointments] = useState<TotalPackAppointment[]>([]);
  const [totalRows, setTotalRows] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [nextRequest, setNextRequest] = useState<string | null>(null);
  const [prevRequest, setPrevRequest] = useState<string | null>(null);

  const fetchAppointments = async (): Promise<void> => {
    setLoading(true);
    try {
      const request: AppointmentRequest = await appointmentService.fetchFingerprintAppointments(
        commonFilters.initDate.format("YYYY-MM-DD"),
        commonFilters.finishDate.format("YYYY-MM-DD"),
        filters.status,
        filters.insurance,
      );
      setAppointments(request.data.results);
      setTotalRows(request.data.count);
      setNextRequest(request.data.next);
      setPrevRequest(request.data.previous);
    } catch (error) {
      setError(error);
      console.error(error);
    }
    setLoading(false);
  };

  const commitTotalPack = async () => {
    setLoading(true);
    try {
      const responses = await Promise.allSettled(
        appointments
          .filter(
            (appointment) =>
              !["connected-successfully", "closed-appointment"].includes(appointment.totalpack_request_status),
          )
          .map(async (appointment) => {
            const commitResponse = await appointmentService.totalpackPaymentConfirm(appointment.id);
            return commitResponse;
          }),
      );

      const allConfirmationFulfilled = responses.every((result) => result.status === "fulfilled");
      const allConfirmationRejected = responses.every((result) => result.status === "rejected");
      const someConfirmationRejected = responses.some(
        (result) => result.status === "rejected" || result?.data?.data?.partially_connected,
      );

      if (allConfirmationFulfilled) {
        await Swal.fire({
          title: "¡Listo!",
          text: "Todas las citas han sido conectadas con éxito.",
          icon: "success",
          confirmButtonText: "Ok",
        });
      } else if (someConfirmationRejected) {
        await Swal.fire({
          title: "¡Error!",
          text: "Algunas citas no se pudieron conectar correctamente. Revisar manualmente.",
          icon: "error",
          confirmButtonText: "Ok",
        });
      } else if (allConfirmationRejected) {
        await Swal.fire({
          title: "¡Error!",
          text: "Ninguna cita se pudo conectar correctamente. Revisar manualmente.",
          icon: "error",
          confirmButtonText: "Ok",
        });
      }
    } catch (error) {
      setError(error);
      console.error(error);
    }
    setLoading(false);
  };

  const valorizeWithTotalPack = async () => {
    setLoading(true);
    try {
      const responses = await Promise.allSettled(
        appointments
          .filter(
            (appointment) =>
              !["connected-successfully", "closed-appointment"].includes(appointment.totalpack_request_status),
          )
          .map(async (appointment) => {
            const commitResponse = await appointmentService.totalpackValuation(appointment.id);
            return commitResponse;
          }),
      );

      const allValuationFulfilled = responses.every((result) => result.status === "fulfilled");
      const allValuationRejected = responses.every((result) => result.status === "rejected");
      const someValuationRejected = responses.some(
        (result) => result.status === "rejected" || result?.data?.data?.partially_connected,
      );

      if (allValuationFulfilled) {
        await Swal.fire({
          title: "¡Listo!",
          text: "Todas las citas han sido valorizadas con éxito.",
          icon: "success",
          confirmButtonText: "Ok",
        });
      } else if (someValuationRejected) {
        await Swal.fire({
          title: "¡Error!",
          text: "Algunas citas no se pudieron valorizar correctamente. Revisar manualmente.",
          icon: "error",
          confirmButtonText: "Ok",
        });
      } else if (allValuationRejected) {
        await Swal.fire({
          title: "¡Error!",
          text: "Ninguna cita se pudo valorizar correctamente. Revisar manualmente.",
          icon: "error",
          confirmButtonText: "Ok",
        });
      }
    } catch (error) {
      setError(error);
    }
    setLoading(false);
  };

  const handleGenerateTotalPack = async () => {
    const response = await callGenerateAppointmentsTotalPack();
    navigate(`/dashboard/${response.id_task}/totalpack-masive-downloader/`);
  };
  const callGenerateAppointmentsTotalPack = async () => {
    setLoading(true);
    let request = undefined;
    try {
      request = await api.post("/v2/totalpack-download", {
        begin_date: commonFilters.initDate.format("YYYY-MM-DD"),
        end_date: commonFilters.finishDate.format("YYYY-MM-DD"),
      });
    } catch (err) {
      setError(err);
    }
    setLoading(false);
    return request?.data;
  };

  const handleTotalPackConnect = async (): Promise<void> => {
    const someConnected = appointments.some(
      (appointment) => appointment.totalpack_request_status === "connected-successfully",
    );
    const allConnected = appointments.every(
      (appointment) => appointment.totalpack_request_status === "connected-successfully",
    );

    if (allConnected) {
      await Swal.fire({
        title: "Error",
        text: "Todas las citas ya han sido conectadas.",
        icon: "error",
        confirmButtonText: "Aceptar",
      });
    } else if (someConnected) {
      const result = await Swal.fire({
        title: "Confirmación de conexión",
        text: "¿Está seguro de que desea conectar todas las citas? Solo se conectarán aquellas que no hayan sido conectados previamente.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Si, conectar todos",
        cancelButtonText: "Cancelar",
      });
      if (result.value) {
        await commitTotalPack();
        await fetchAppointments();
      }
    } else {
      const result = await Swal.fire({
        title: "Confirmación de conexión",
        text: `¿Está seguro de que desea conectar las ${appointments.length} citas?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Si, conectar todos",
        cancelButtonText: "Cancelar",
      });
      if (result.value) {
        await commitTotalPack();
        await fetchAppointments();
      }
    }
  };

  const handleFilterButton = (): void => {
    fetchAppointments();
  };

  const fetchNewPage = async (action: "next" | "previous"): Promise<void> => {
    let nextUrl = action === "next" ? nextRequest : prevRequest;
    if (!nextUrl) {
      return;
    }
    if (isProd && nextUrl.includes("http://")) {
      nextUrl = nextUrl.replace("http://", "https://");
    }
    setLoading(true);
    try {
      const request = await api.request({
        method: "GET",
        url: nextUrl,
      });
      setAppointments(request.data.results);
      setTotalRows(request.data.count);
      setNextRequest(request.data.next.replace("http://", "https://"));
      setPrevRequest(request.data.previous.replace("http://", "https://"));
    } catch (err) {
      console.log(err);
      setError(err);
    }
    setLoading(false);
  };

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    const action = currentPage > newPage ? "previous" : "next";
    fetchNewPage(action);
    setCurrentPage(newPage);
  };

  useEffect((): void => {
    fetchAppointments();
  }, []);

  return (
    <PrivateRoute>
      <LoadingError
        error={error}
        loading={loading}
      />
      {!loading && isEmpty(error) && (
        <>
          <div className="px-12 pb-8">
            <div className="flex flex-col items-center">
              <h1 className="italic">Appointments Total Pack</h1>
              <div className="flex items-center justify-center flex-wrap">
                <div className="my-2 mx-4">
                  <MuiPickersUtilsProvider
                    utils={MomentUtils}
                    locale="es"
                  >
                    <KeyboardDatePicker
                      variant="inline"
                      format="LL"
                      margin="normal"
                      label="Fecha inicio"
                      value={commonFilters?.initDate}
                      autoOk
                      onChange={(date: moment.Moment) => {
                        filtersDispatch({
                          type: "UPDATE_INITDATE",
                          payload: date,
                        });
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </div>
                <div className="my-2 mx-4">
                  <MuiPickersUtilsProvider
                    utils={MomentUtils}
                    locale="es"
                  >
                    <KeyboardDatePicker
                      disableToolbar
                      variant="inline"
                      format="LL"
                      margin="normal"
                      label="Fecha término"
                      value={commonFilters?.finishDate}
                      autoOk
                      onChange={(date: moment.Moment) => {
                        filtersDispatch({
                          type: "UPDATE_FINISHDATE",
                          payload: date,
                        });
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </div>
              </div>
              <div className="flex items-center justify-center flex-wrap">
                <div className="my-2 mx-4">
                  <FormControl>
                    <Select
                      value={filters?.status}
                      onChange={(e) =>
                        setFilters({
                          ...filters,
                          status: e.target.value as string,
                        })
                      }
                    >
                      <MenuItem value="without-connection">Sin conexión</MenuItem>
                      <MenuItem value="all">Todos</MenuItem>
                      <MenuItem value="connected-successfully">Enviado a tablet</MenuItem>
                      <MenuItem value="partially-connected">Enviado parcialmente a tablet</MenuItem>
                      <MenuItem value="valorized-successfully">Valorizado</MenuItem>
                      <MenuItem value="partially-valorized">Valorizado parcialmente</MenuItem>
                      <MenuItem value="closed-appointment">Cita cerrada</MenuItem>
                    </Select>
                  </FormControl>
                  <FormControl>
                    <Select
                      value={filters?.insurance}
                      onChange={(e) =>
                        setFilters({
                          ...filters,
                          insurance: e.target.value as string,
                        })
                      }
                    >
                      <MenuItem value="cruzblanca">CruzBlanca</MenuItem>
                      <MenuItem value="nueva_masvida">Nueva Más Vida</MenuItem>
                      <MenuItem value="all">Todos</MenuItem>
                      <MenuItem value="fonasa">Fonasa</MenuItem>
                    </Select>
                  </FormControl>
                </div>
                <div className="my-2 mx-4">
                  <button
                    className="max-h-10 border-primary text-primary"
                    variant="outlined"
                    color="primary"
                    onClick={handleFilterButton}
                  >
                    Filtrar
                  </button>
                </div>
                <div className="my-2 mx-4">
                  <Button
                    variant="outlined"
                    style={{ color: "green" }}
                    onClick={valorizeWithTotalPack}
                  >
                    Valorizar
                  </Button>
                </div>
                <div className="my-2 mx-4">
                  <Button
                    variant="outlined"
                    style={{ color: "green" }}
                    onClick={handleTotalPackConnect}
                  >
                    Enviar a tablet
                  </Button>
                </div>
                <div className="my-2 mx-4">
                  <Button
                    variant="outlined"
                    style={{ color: "green" }}
                    onClick={handleGenerateTotalPack}
                  >
                    Descargar zip de archivos
                  </Button>
                </div>
              </div>
            </div>
          </div>
          <TotalPackDashboardGrid
            appointments={appointments}
            totalRows={totalRows}
            currentPage={currentPage}
            handleChangePage={handleChangePage}
          />
        </>
      )}
    </PrivateRoute>
  );
};

export default IndexPage;
